<template>
  <div class="fill-height py-3 py-md-10">
    <v-container class="flex-center fill-height">
      <div class="notfound-text">Page not found.</div>
    </v-container>
  </div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>
  .notfound-text {
    font-size: 50px;
    color: rgba(0,0,0,.3);
  }
</style>
